var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"price-list-2dd9e1aabb88bd87a78baafb2e27a278a061e834"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/price-list";

import { environment } from './environments/environment';
import { initSentry, OWNERS } from '../../sentry.config.base';

initSentry(environment.env, 'price-list', OWNERS.AMERICA);
